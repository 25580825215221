<template>
  <div class="mdc-form-field" style="display: flex;align-items: center;">
    <div class="mdc-checkbox">
      <input type="checkbox" :checked="selected" v-on:change="changeCheckBox" id="my-checkbox" class="mdc-checkbox__native-control" />
      <div class="mdc-checkbox__background">
        <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
          <path class="mdc-checkbox__checkmark__path" fill="none" stroke="white" d="M1.73,12.91 8.1,19.28 22.79,4.59" />
        </svg>
        <div class="mdc-checkbox__mixedmark"></div>
      </div>
    </div>

    <label for="my-checkbox" class="mb-0">{{ label }}</label>
  </div>
</template>

<script>
import { MDCCheckbox, MDCCheckboxFoundation } from '@material/checkbox';
//import '@material/checkbox/dist/mdc.checkbox.min.css';
import '@/assets/styles/mdc.checkbox.min.css';

export default {
  name: 'navigation',
  props: ['selected', 'label'],
  data() {
    return {
      //label: '',
      selectedLocal: null
    };
  },
  methods: {
    changeCheckBox(e) {
      this.selectedLocal = e.target.checked;
      this.$emit('checkboxChanged', this.selectedLocal);
    }
  },
  created: function() {}
};
</script>

<style lang="scss"></style>
